.add-payment-page {
  .DayPicker-Day {
    text-align: center;
  }

  .DayPicker-NavButton--prev {
    left: 15px;
  }

  .DayPicker-NavButton--next {
    right: 20px;
  }

  .DayPicker-wrapper {
    padding: 0 40px;
  }
}
