.bookings-full-screen-page {
  min-width: 3000px;
  height: 100%;
  background-color: $lightestGrey;

  .records-list-container .small-spinner {
    position: sticky;
    left: 20px;
  }

  .records-list-item__wrapper {
    grid-template-areas:
        ". . . . . ."
        ". . . . . ."
        ". . . . . .";
    grid-template-columns: 1.5fr 1.5fr 0.4fr 1fr 0.6fr 0.6fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 1.5fr;
  }

  .dropdowns-container {
    position: sticky;
    left: 10px;
    width: calc(100vw - 50px);
  }

  .records-totals-counter {
    @media (max-width: $tabletWidth) {
      text-align: center;
      width: fit-content;
      margin: 20px;
    }
  }

  .white-block-container {
    padding: 0;

    &__top-bar {
      width: calc(100vw - 20px);
    }
  }
}

.booking-confirmation-form,
.booking-cancellation-form {
  padding-top: 10px;

  .input-label-field {
    justify-content: flex-start;
    margin-bottom: 10px;

    label {
      font-size: 16px;
      margin-right: 10px;
    }
  }

  em {
    font-size: 12px;
    line-height: 14px;
    color: $lighterGrey;
  }

  & > .btn-submit {
    margin-top: 20px;
    margin-left: auto;
  }

  & > .small-spinner {
    margin-top: 22px;
    margin-left: auto;
  }

  .file-uploader .small-spinner {
    margin-top: -2px;
  }
}
