.ReactModal__Body--open,
.noScroll {
  overflow: hidden !important;
  overflow-scrolling: auto !important;
}

.ReactModal__Overlay {
  z-index: 1001;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 700px;
  padding: 20px;
  background-color: $white;
  border: 1px solid $lighterGrey;

  &.small-size {
    max-width: 450px;
  }

  &.auto-size {
    max-width: fit-content;
  }

  &.full-screen-size {
    background-color: $lightestGrey;
    height: calc(100vh - 50px);
    width: 90%;
    max-width: 100%;
    overflow: auto;
  }

  &.custom-modal {
    text-align: center;

    .btns-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        flex: 1 1 calc(50% - 5px);
        min-width: auto;
        max-width: 225px;
        height: auto;

        &-delete {
          margin-left: 10px;
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
    color: $mediumGrey;
    border: none;
    background-color: unset;

    &:hover {
      text-shadow: 0 0 1px $mediumGrey;
    }
  }

  .DayPicker-Day {
    text-align: center;
  }

  .DayPicker-NavButton--prev {
    left: 15px;
  }

  .DayPicker-NavButton--next {
    right: 20px;
  }

  .DayPicker-wrapper {
    padding: 0 40px;
  }
}
