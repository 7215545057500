.dropdown {
  min-width: 300px;
  max-width: fit-content;

  .dropdown-list {
    &__control {
      z-index: 10;
      width: 300px;
      color: $lightGrey;
      font-size: 18px;
      line-height: 21px;
      cursor: pointer;
    }

    &__menu {
      z-index: 11;
      width: 200%;
      margin: 0;

      @media (max-width: $mobileWidth) {
        width: 100%;
      }
    }

    &__menu-list {
      max-height: 400px;
      padding: 0;
    }

    &__item {
      display: flex;
      align-items: flex-start;
      max-height: 80px;
      overflow: hidden;
      cursor: pointer;
      transition: all .25s;

      &:hover {
        background-color: $lightestGrey;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $lighterGrey;
      }

      .item {
        &-image {
          flex-shrink: 0;
          width: 115px;
          height: 80px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }

        &-content {
          width: 100%;
          padding: 5px 10px 5px 20px;
          overflow: hidden;

          &.no-image {
            padding-left: 135px;
          }
        }

        &-title {
          margin: 5px 0;
          font-size: 18px;
          line-height: 21px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &-desc {
          font-size: 14px;
          line-height: 16px;
          color: $lightGrey;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

  }

  &.empty {
    .dropdown-list__menu {
      width: 100%;
    }
  }
}

.dropdowns-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 10;

  .dropdown__wrapper {
    margin-bottom: 25px;

    &:not(:last-child) {
      margin-right: 25px;
    }
  }
}
