.contract-page {
  .white-block-container {
    h2 {
      text-align: center;
      font-size: 18px;
      color: $darkGrey;
      text-transform: capitalize;
    }

    .btn-submit {
      margin: 30px auto 0 auto;
    }
  }
}
