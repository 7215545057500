.login-page {
  padding: 100px 10px 20px 10px;

  &__form {
    text-align: center;

    &-title {
      margin-bottom: 50px;
      color: $mediumGrey;
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .input-text {
        width: 300px;
        margin-bottom: 20px;
      }
    }
  }
}
