.page-header {
  align-items: center;
  display: flex;
  min-height: 95px;
  margin-bottom: 25px;
  width: 100%;

  .breadcrumbs {
    flex-grow: 1;

    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: $darkGrey;
      text-transform: capitalize;
    }
  }

  .notification-icon {
    padding: 0 40px;
  }

  .user-menu {
    position: relative;
    cursor: pointer;

    &__title {
      color: $lightGrey;
      padding: 10px 0;

      &::after {
        content: '\25BC';
        margin-left: 10px;
      }
    }

    &__list {
      position: absolute;
      top: 100%;
      right: 0;
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      border: 1px solid transparent;
      border-radius: 4px;
      z-index: 1000;
      opacity: 0;
      transform: scaleZ(0);
      transition: all 0.3s;
    }

    &:hover {
      .user-menu__title::after {
        content: '\25B2';
      }

      .user-menu__list {
        border-color: $lighterGrey;
        opacity: 1;
        transform: scaleZ(1);
      }
    }
  }
}
