.calendar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 0 50px;

  .calendar {
    &__caption {
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
      color: $darkGrey;
      text-align: center;
    }

    &__legend {
      padding: 20px;
    }
  }

  .btn-clear {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    background-color: unset;
    color: $green;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &__wrapper {
      align-self: center;
      position: relative;
      width: 100%;
      height: 20px;
    }
  }
}

.DayPicker {
  flex-grow: 1;
  padding: 20px 0;

  &-wrapper {
    padding: 0 50px;
  }

  &-NavButton {
    top: 160px;
    background-image: unset;

    &--prev {
      left: 0;

      &::after {
        content: '\25c0';
      }
    }

    &--next {
      right: 0;

      &::after {
        content: '\25b6';
      }
    }

    &::after {
      color: $lighterGrey;
      display: block;
      font-size: 30px;
    }
  }

  &-Month {
    width: calc(50% - 2em);
    max-width: 500px;
    min-width: 300px;
    outline: 1px solid $lighterGrey;
  }

  &-Caption {
    text-align: center;
    padding: 15px;
    font-size: 15px;
    color: $darkGrey;
    background-color: $snowWhite;
  }

  &-Weekday {
    font-size: 14px;
    line-height: 16px;
    color: $black;
  }

  &-Week {
    border-top: 1px solid $black;
  }

  &-Day {
    width: 14.285714%;
    height: 50px;
    padding: 5px 0;
    text-align: left;
    vertical-align: top;
    border-radius: 0;
    overflow: hidden;

    &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: $lightestGrey;
      color: inherit;
      font-weight: normal;
      transition: background-color .3s;

      &:hover,
      &:focus {
        background-color: $lighterGrey;
      }
    }

    .day {

      &__row {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      &__date {
        font-size: 14px;
      }

      &__info {
        font-size: 18px;
        font-style: italic;
        font-family: serif;
        font-weight: bold;
      }

      &__availability {
        &.enough {
          color: $success;
        }

        &.not-enough {
          color: $orangeAlert;
        }

        &.none {
          color: $redAlert;
        }
      }
    }
  }
}
