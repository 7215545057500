.sort {
  flex-shrink: 0;
  min-width: 300px;
  max-width: fit-content;
  font-size: 18px;
  line-height: 21px;
  background-color: $white;

  .sort-list {
    &__control {
      cursor: pointer;
    }

    &__menu {
      margin: 0;
    }

    &__menu-list {
      padding: 0;
    }

    &__item {
      padding: 10px;
      font-size: 14px;
      line-height: 16px;
      color: $black;
      cursor: pointer;
      transition: all .25s;

      &:hover {
        background-color: $lightestGrey;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $lighterGrey;
      }
    }

    &__placeholder {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
