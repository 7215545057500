.custom-switch {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__inner {
    flex-shrink: 0;
    position: relative;
    width: 40px;
    height: 20px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__title {
    margin-left: 10px;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
  }

  &__hint {
    margin-top: 5px;
    font-style: italic;
    font-size: 12px;
    line-height: 14px;
    color: $lighterGrey;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $lightGrey;
    transition: .3s;

    &:before {
      position: absolute;
      content: "";
      height: 22px;
      width: 20px;
      left: 0;
      top: -1px;
      background-color: $lighterGrey;
      transition: .3s;
    }
  }

  input:checked + .custom-switch__slider {
    background-color: $green;

    &:before {
      transform: translateX(20px);
    }
  }

  input:focus + .custom-switch__slider {
    box-shadow: 0 0 1px $green;
  }
}
