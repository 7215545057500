.custom-products-page {
  .white-block-container {
    padding: 0;
    min-width: $tabletWidth;
  }

  .records-list-item__wrapper {
    grid-template-columns: 1.5fr 1fr 0.6fr 0.7fr 1.0fr 1.0fr 0.6fr;
  }
}
