.bookings-page {
  &__content-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: $tabletWidth) {
      flex-wrap: wrap;
    }

    &--left {
      flex: 1 0 calc(19% - 20px);
      margin-right: 20px;
      height: fit-content;
      position: sticky;
      top: 10px;

      @media (max-width: $tabletWidth) {
        flex: 1 1 100%;
        position: static;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 20px;
      }

      .bookings-page__left-content-item {
        &:not(:last-child) {
          margin-bottom: 20px;

          @media (max-width: $tabletWidth) {
            margin-right: 20px;
          }
        }

        &:nth-child(1) {
          background-color: $white;
          border: 1px solid $lighterGrey;
          border-radius: 5px;
          padding: 10px;
        }

        @media (max-width: $tabletWidth) {
          margin-bottom: 20px;
        }
      }

      .sort {
        min-width: 100%;

        @media (max-width: $tabletWidth) {
          min-width: 300px;
          max-width: 100%;
        }
      }
    }

    &--right {
      flex: 0 1 81%;

      @media (max-width: $tabletWidth) {
        flex: 1 1 100%;
      }

      .booking-list__item {
        padding: 0 20px;

        .list-item {
          &__wrapper {
            padding: 20px 0;
            border-bottom: 1px solid $lighterGrey;
          }

          &__line {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            line-height: 21px;
            color: $darkGrey;

            @media (max-width: 1370px) {
              flex-wrap: wrap;
            }

            .booking {
              &-number {
                width: 395px;
                overflow: hidden;
                text-overflow: ellipsis;

                @media (max-width: 1550px) {
                  display: flex;
                  flex-direction: column;
                  width: 220px;
                }

                @media (max-width: 1370px) {
                  flex-direction: row;
                  width: calc(100% - 180px);
                }

                @media (max-width: 1000px) {
                  flex-direction: column;
                }

                &__text {
                  font-weight: bold;

                  @media (max-width: 1370px) {
                    width: 210px;
                  }
                }

                .order-date {
                  font-size: 16px;
                  color: $mediumGrey;
                  white-space: nowrap;
                }
              }

              &-persons {
                width: 300px;

                @media (max-width: 1370px) {
                  display: flex;
                  order: 1;
                  width: 100%;

                }
              }

              &-guests {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                max-width: 100%;

                .small-icons {
                  .icon-person {
                    width: 16px;
                    height: 16px;
                  }
                }
              }

              &-holder-name {
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @media (max-width: 1370px) {
                  min-width: 210px;
                }
              }

              &-order-date {
                min-width: 140px;
              }

              &-dates {
                max-width: 210px;
                font-style: italic;
                white-space: nowrap;

                @media (max-width: 1370px) {
                  order: 1;
                }
              }

              @mixin booking-status {
                text-align: right;
                width: 180px;
                white-space: nowrap;
              }

              &-status {
                &__confirmed {
                  @include booking-status;
                  color: $success;
                }

                &__pending {
                  @include booking-status;
                  color: $orangeAlert;
                }

                &__cancelled {
                  @include booking-status;
                  color: $redAlert;
                }

                &__abandoned {
                  @include booking-status;
                  color: $mediumGreyAlert;
                }
              }
            }

            &.property-details {
              justify-content: flex-start;
              font-size: 14px;
              line-height: 16px;
              color: $lighterGrey;
            }
          }

          &__details {
            @media (max-width: 1370px) {
              margin-bottom: 10px;
            }

            &:not(:last-of-type) {
              padding-right: 10px;
            }
          }
        }
      }

    }
  }

  .white-block-container {
    width: 100%;
    padding: 0;
    border-radius: 5px;

    &__top-bar {
      width: 100%;
      flex-wrap: wrap;
      border-radius: 5px 5px 0 0;
    }

    .records-totals-counter {
      padding: 0 15px;

      @media (max-width: $tabletWidth) {
        text-align: center;
        width: fit-content;
        padding: 0;
        margin: 20px;
      }
    }
  }
}

.pagination {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 15px;
  font-size: 21px;
  background-color: $snowWhite;
  list-style: none;

  &-item {
    text-align: center;

    &.active {
      .pagination-link {
        font-weight: bold;
      }
    }

    &.disabled {
      .pagination-link {
        color: $lighterGrey;
        pointer-events: none;
      }
    }
  }

  &-link {
    padding: 0 15px;
    font-size: 18px;
    line-height: 35px;
    text-decoration: none;
    color: $darkGrey;
  }
}
