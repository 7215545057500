.settings-page {
  h2 {
    font-size: 18px;
    color: $darkGrey;
    text-transform: capitalize;

    &.no-settings-msg {
      text-align: center;
    }
  }
}
