.add-payment-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  background-color: white;
  border: 2px solid $lighterGrey;

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;

    .input-label-field {
      justify-content: space-between;

      &:not(:first-child) {
        margin-top: 15px;
      }

      input {
        flex: auto;
        padding: 3px;
        margin-left: 20px;
      }
    }

    .btn {
      margin-top: 25px;
      width: 100%;
    }

    .small-spinner {
      margin: 27px auto 0 auto
    }
  }

  &__btns-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .btn {
      flex: 1 1 calc(50% - 3px);
      min-width: auto;

      &:first-child {
        margin-right: 6px;
      }
    }
  }
}
