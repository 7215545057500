.payments-list {
  background-color: $white;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  & > h2 {
    text-align: center;
    font-size: 18px;
    color: $darkGrey;
    text-transform: capitalize;
  }

  &__item {
    &.list-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      line-height: 21px;
      padding: 20px 0;
      border-bottom: 1px solid $lighterGrey;

      @media (max-width: 950px) {
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
      }

      .list-item__details {
        flex: 1 1 18%;

        &:first-child {
          flex-basis: 28%;
        }

        &:not(:last-child) {
          padding-right: 5px;

          @media (max-width: 950px) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
