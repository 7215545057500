* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $Roboto;
}

.Toastify__toast--success {
  background-color: $green;
}

.white-block-container {
  position: relative;
  flex-grow: 1;
  padding: 35px;
  background-color: $white;
  border: 1px solid $lightestGrey;

  &__top-bar {
    position: sticky;
    top: 0;
    left: 0;
    width: calc(100vw - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: $snowWhite;
    border-bottom: 1px solid $lighterGrey;

    @media (max-width: $tabletWidth) {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: $mobileWidth) {
      position: static;
    }
  }
}

.records-list {
  &-container {
    background-color: white;
    padding: 20px;
  }

  &-item {
    &__wrapper {
      display: grid;
      grid-template-columns: 1fr 0.5fr 1fr 0.6fr 0.6fr 0.6fr 0.8fr 0.8fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
      gap: 5px 10px;
      grid-template-areas: '. . . . . .' '. . . . . .' '. . . . . .';
      margin-bottom: 15px;
    }

    &__details {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-bottom: 20px;

      .btn {
        margin: 0 7px;
      }
    }
  }
}

.record-status__text {
  text-transform: uppercase;
  margin-left: 20px;

  &--refunded {
    color: $redAlert;
  }

  &--cancelled {
    color: $redAlert;
  }
}

