.booking-search {
  position: relative;

  &::after {
    content: url("../../../../images/icons/search.svg");
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &__input {
    min-width: 300px;
    padding-right: 40px;
    font-size: 18px;
    line-height: 35px;
  }
}
