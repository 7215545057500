//fonts
$Roboto: Roboto, sans-serif;

//colors
$green: #589E46;
$black: #0A0A0A;
$white: #FAFAFA;
$snowWhite: #F9FAFB;
$darkGrey: #34393D;
$mediumGrey: #455058;
$lightGrey: #716E6E;
$lighterGrey: #C4C4C4;
$lightestGrey: #F0F2F5;
$success: #27AE60;
$orangeAlert: #F2994A;
$redAlert: #EB5757;
$mediumGreyAlert: #EB5757;

//screen sizes
$tabletWidth: 1195px;
$mobileWidth: 767px;

