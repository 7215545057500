.btn-icon {
  &.with-icon {
    justify-content: space-between;
  }

  &.icon-right {
    flex-direction: row-reverse;
  }

  & > img {
    padding: 0 12px;
  }
}
