.spinner-wrapper {
  position: fixed;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  background-color: $white;
  border: 2px solid $lighterGrey;
  padding: 20px;
  transform: translate(-50%);
  z-index: 1001;
}
