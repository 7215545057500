.app {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
  position: relative;
  background-color: $lightestGrey;

  .full-screen-page {
    padding-top: 10px;
    font-size: 13px;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
  }
}

.navigation-wrapper {
  height: 100%;
  padding: 0 10px 22px 135px;

  @media (max-width: $tabletWidth) {
    padding-right: 0;
    padding-left: 85px;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100px;
    background-color: $darkGrey;
    text-align: center;
    z-index: 999;

    @media (max-width: $tabletWidth) {
      width: 70px;
    }

    &__list {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;

      &--dropdown {
        position: absolute;
        top: 0;
        left: 100%;
        flex-direction: row;
        opacity: 0;
        transform: scaleZ(0);
        transition: all 0.3s;

        .navigation__list-item {
          border-left: 1px solid $black;
        }
      }

      &-item {
        position: relative;
        width: 100px;
        height: 100px;
        list-style: none;

        @media (max-width: $tabletWidth) {
          width: 70px;
          height: 70px;
        }

        a {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 5px;
          color: $white;
          font-size: 14px;
          line-height: 1.15;
          text-decoration: none;
          overflow: hidden;
          text-overflow: ellipsis;
          background-color: $darkGrey;

          &:hover {
            box-shadow: inset 0 0 10px 0 #ffffff30;
            background-color: $mediumGrey;
          }

          &:active {
            box-shadow: inset 0 0 15px 0 #ffffff30;
          }

          &::before {
            padding-bottom: 21px;

            @media (max-width: $tabletWidth) {
              padding-bottom: 5px;
            }
          }

          &.home::before {
            content: url('../../../../images/icons/home.svg');
          }

          &.sales::before {
            content: url('../../../../images/icons/sales.svg');
          }

          &.inventory::before {
            content: url('../../../../images/icons/inventory.svg');
          }

          &.settings::before {
            content: url('../../../../images/icons/settings.svg');
          }

          &.bookings::before {
            content: url('../../../../images/icons/bookings.svg');
          }

          &.custom::before {
            content: url('../../../../images/icons/bookings.svg');
          }

          &.contract::before {
            content: url('../../../../images/icons/contract.svg');
          }

          &.payments::before {
            content: url('../../../../images/icons/contract.svg');
          }
        }

        &:hover .navigation__list--dropdown {
          opacity: 1;
          transform: scaleZ(1);
        }
      }

      &:not(&--dropdown) .navigation__list-item {
        border-bottom: 1px solid $black;
      }
    }
  }
}

.report-wrapper {
  .errors-list {
    padding-left: 15px;
    margin-bottom: 40px;
    list-style: none;

    &__item {
      margin-bottom: 10px;
    }
  }

  .error {
    &-title {
      margin: 30px 0;
      font-weight: bold;
      text-align: center;
      color: $darkGrey;
    }

    &-name {
      color: $redAlert;
    }

    &-desc {
      color: $mediumGrey;
    }
  }

  .btn {
    margin: auto;
  }
}
