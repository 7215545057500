.dropdown-button {
  position: relative;
  width: fit-content;

  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    list-style: none;
    border: 1px solid $lighterGrey;
    border-radius: 4px;
    transform: scaleZ(0);
    z-index: 10;
    transition: all .2s;

    &.visible {
      transform: scaleZ(1);
      opacity: 1;

      .dropdown-button__list-item:not(:last-of-type) {
        border-bottom: 1px solid $lighterGrey;
      }
    }
  }

  .list-item__button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background-color: $white;
    transition: all .2s;

    &:hover {
      box-shadow: 0 0 5px $black;
    }
  }
}
