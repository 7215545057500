.inventory-page {
  .dropdown__wrapper:nth-child(2) {
    margin-right: 0;
  }

  .btn-copy-to-clipboard {
    margin-left: 5px;
    margin-bottom: 25px;
  }

  .btns-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-show-instructions {
      background: none;
      border: 1px solid transparent;
      font-family: serif;
      font-size: 25px;
      font-style: italic;
      margin-left: 15px;
      margin-right: auto;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      transition: all 0.2s;

      &:hover,
      &:focus {
        border-color: $lighterGrey
      }
    }
  }

  .calendar-wrapper {
    margin-top: 35px;
  }
}

.inventory-page-instructions {
  display: flex;
  flex-direction: column;

  .image-preview {
    display: block;
    width: 100%;
    margin: 20px auto 30px;
  }

  .import-btn {
    margin-left: auto;
  }

  .rules-list {
    padding-left: 5px;
    list-style-type: '- ';
    list-style-position: inside;

    &__item {
      margin-bottom: 7px;
      font-size: 14px;
      line-height: 18px;
      color: $darkGrey;
    }
  }
}

.inventory-page-update-form {
  .date-range {
    margin-top: 15px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
  }

  .input-text {
    max-width: 90px;
    margin-left: 20px;
  }

  &__button {
    margin-top: 20px;
    margin-left: auto;
  }

  .radio-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .radio-wrapper input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background-color: $lightestGrey;
    border-radius: 50%;
    transition: background-color 0.3s;
  }

  .radio-wrapper:hover input ~ .checkmark {
    background-color: $lighterGrey;
  }

  .radio-wrapper input:checked ~ .checkmark {
    background-color: $green;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .radio-wrapper input:checked ~ .checkmark:after {
    display: block;
  }

  .radio-wrapper .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $lightestGrey;
  }
}
