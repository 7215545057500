.booking-confirmation-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .white-block-container {
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 300px;
      margin: 0 auto;
    }

    & > div, h1 {
      text-align: center;
    }

    table {
      border-spacing: 20px;
      margin-bottom: 25px;
    }

    input {
      margin-bottom: 5px;
    }

    input, button {
      padding: 3px;
      width: 100%;
    }
  }
}
