.records-totals-counter {
  min-width: 160px;
  text-align: center;
  color: $darkGrey;

  @media (max-width: $tabletWidth) {
    width: 100%;
    margin: 0;
    text-align: left;
  }
}
