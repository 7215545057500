.small-spinner {
  width: 40px;
  height: 40px;

  &:after {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: $lighterGrey transparent $lighterGrey transparent;
    animation: small-spinner-animation 1.2s linear infinite;
  }
}

@keyframes small-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
