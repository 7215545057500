.input {
  max-width: 100%;

  &-text {
    padding-left: 10px;
    color: $lightGrey;
    font-size: 14px;
    line-height: 30px;
    background-color: $white;
    border: 1px solid $lighterGrey;
    border-radius: 4px;

    &:disabled {
      background-color: $lighterGrey;
    }
  }
}
