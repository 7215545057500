.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &-submit {
    min-width: 202px;
    height: 42px;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    background-color: $green;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    color: $white;
  }

  &-delete {
    min-width: 202px;
    height: 42px;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    background-color: $orangeAlert;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    color: $white;
  }

  &-logout {
    background-color: $white;
    font-size: 16px;
    padding: 12px 0;
    min-width: 105px;
    border: none;
    color: $darkGrey;
    display: block;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0 0 5px $black;
    }
  }

  &-copy-to-clipboard {
    width: 20px;
    height: 20px;
    border: none;
    background-color: unset;
    background-image: url('../../../../images/icons/clipboard.svg');
    background-repeat: no-repeat;
    background-position: center center;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

