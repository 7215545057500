.date-range {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;

  &__dash {
    margin: 0 10px;
  }

  .DayPickerInput {
    max-width: 90px;

    &-Overlay {
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.15);
    }

    input {
      max-width: 100%;
      padding-left: 10px;
      color: $lightGrey;
      font-size: 14px;
      line-height: 30px;
      background-color: $white;
      border: 1px solid $lighterGrey;
      border-radius: 4px;
    }

    .DayPicker {
      padding: 10px;

      &-wrapper {
        max-width: 280px;
        padding: 0;
      }

      &-NavButton {
        margin-right: 0;
        top: 0;

        &::after {
          font-size: 20px;
        }
      }

      &-Month {
        margin: 0;
        outline: none;
      }

      &-Caption {
        padding: 5px;
        background-color: unset;
      }

      &-Week {
        border-top: none;
      }

      &-Day {
        height: auto;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}
