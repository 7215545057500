.input-label-field {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.label-first {
    & > label {
      order: 0;
    }

    & > input {
      order: 1;
    }
  }
}
